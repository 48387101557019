<template>
  <div v-if="options?.length">
    <div v-if="label" class="font-bold pb-2xs">
      {{ t(label) }}
    </div>
    <Dropdown
      v-model="value"
      :options="options"
      :add-default="props.addDefault"
    />
  </div>
</template>
<script setup lang="ts">
import type { FilterOptions } from '@/components/components/advancedList/useAdvancedList';
import type { DropdownOption } from '@/components/components/advancedList/components/dropdown/useDropdown';
import Dropdown from '@/components/components/advancedList/components/dropdown/dropdown.vue';

const props = defineProps({
  filterKey: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  filters: {
    type: Object as PropType<Record<string, any>>,
    required: true,
    default: () => ({}) as Record<string, any>,
  },
  filterOptions: {
    type: Object as PropType<FilterOptions>,
    required: true,
    default: () => ({}) as FilterOptions,
  },
  addDefault: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits<{
  (e: 'changeFilter', key: string, value: any): void;
}>();

const { t } = useTrans();

const value = ref<string>(props.filters[props.filterKey] ?? '');

watch(value, (newValue) => emits('changeFilter', props.filterKey, newValue));

watch(
  () => props.filters[props.filterKey],
  (nv) => {
    if (value.value !== nv) {
      value.value = nv;
    }
  },
);

const options = computed(() => {
  if (!props.filterOptions[props.filterKey]) {
    return null;
  }
  return props.filterOptions[props.filterKey].values.map(
    ({ id, label, isAvailable }) => {
      return { key: id, label, isAvailable } as DropdownOption;
    },
  );
});
</script>
